import request from '@/utils/request';

export function queryCheckList(params) {
  return request({
    url: '/tanker_check_up/data',
    method: 'get',
    params: {
      ...params,
      type: '1'
    }
  });
}

export function exportCheck(params) {
  return request({
    url: '/tanker_check_up/export',
    method: 'get',
    responseType: 'blob',
    params: {
      ...params,
      type: '1'
    }
  });
}

export function saveCheck(data) {
  return request({
    url: '/tanker_check_up/tanker_save',
    method: 'post',
    data
  });
}

export function saveTankerCheck(data) {
  return request({
    url: '/tanker_check_up/add_form',
    method: 'post',
    data
  });
}

export function queryHistory(params) {
  return request({
    url: '/tanker_check_up/data',
    method: 'get',
    params: {
      ...params,
      type: '0'
    }
  });
}

export function exportHistory(params) {
  return request({
    url: '/tanker_check_up/export',
    method: 'get',
    responseType: 'blob',
    params: {
      ...params,
      type: '0'
    }
  });
}
