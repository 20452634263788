<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <div style="width: 100%; display: flex; justify-content: space-between;">
        <div>
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            class="export-btn"
            >新增</el-button
          >
        </div>
        <el-form :inline="true" size="mini">
          <AreaField
            v-model="searchParams.area_code"
            @change="getStationList"
          />
          <el-form-item>
            <el-select
              v-model="searchParams.site_id"
              filterable
              :clearable="true"
              placeholder="加油站（可输入）"
            >
              <el-option
                v-for="item in gasData"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="handleQuery"
            >
              查询
            </el-button>
            <el-button
              type="primary"
              icon="el-icon-arrow-up"
              size="mini"
              @click="handleExport"
            >
              导出
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @currentChange="handleCurrentChange"
      :loading="tableLoading"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #actions="{ scope }">
        <el-button size="mini" type="primary" @click="handleEdit(scope.row)">
          详情
        </el-button>
      </template>
    </default-table>

    <!-- 新增检查清单 -->
    <el-dialog
      :visible.sync="visible"
      width="900px"
      custom-class="dialog"
      :title="dialogTitle"
      :close-on-click-modal="false"
      @close="handleCancel"
    >
      <!-- :show-close="false" -->
      <!-- :rules="rules" -->
      <ele-form
        v-model="formData"
        :form-desc="formDesc"
        labelPosition="right"
        ref="form"
        :span="24"
        :isShowSubmitBtn="isShowSubmitBtn"
        :isShowCancelBtn="isShowSubmitBtn"
        :isShowBackBtn="false"
        :request-fn="submitUpload"
        :isShowErrorNotify="false"
        @cancel="handleCancel"
        :form-attrs="{
          size: 'small'
        }"
        :disabled="disabledForm"
      >
        <template v-slot:only_id="{}">
          <el-upload
            accept=".jpg, .jpeg, .png, .gif"
            ref="upload"
            multiple
            list-type="picture-card"
            :limit="fileLimit"
            action="/api/info/controlcheck/img_add"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :before-remove="handleBeforeRemove"
            :on-exceed="handleExceed"
            :on-preview="handlePictureCardPreview"
            :file-list="fileList"
            :http-request="uploadFile"
            :auto-upload="false"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </template>
      </ele-form>
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
// import List from '@/components/List';
import {
  queryCheckList,
  exportCheck,
  saveTankerCheck
  // saveCheck,
} from '@/api/gas_check';
import { formDesc, columns, formDescDetail } from './checkList';
import moment from 'moment';
import EleForm from 'vue-ele-form';
import mixins from '@/mixins';
import axios from 'axios';

export default {
  mixins: [mixins],
  components: {
    PageHeader,
    DefaultTable,
    EleForm
    // List
  },
  data() {
    return {
      tableLoading: true,
      visible: false,
      detailInfo: [],
      formData: {
        check_time: moment().format('yyyy-MM-DD')
      },
      disabledForm: false,
      searchParams: {},
      columns,
      // 图片上传
      fileData: '', // new formData对象,  // 文件上传数据（多文件合一）
      fileList: [], // upload多文件数组
      fileLimit: 10,
      dialogImageUrl: false,
      // dialogVisible: false,
      isShowSubmitBtn: true,
      dialogTitle: '',
      formDesc: formDesc,
      data: [],
      tankerList: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      }
    };
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleAdd() {
      this.dialogTitle = '新增';
      this.visible = true;
      this.isShowSubmitBtn = true;
      this.formDesc = formDesc;
    },
    handleEdit(e) {
      this.dialogTitle = '详情';
      this.visible = !this.visible;
      this.isShowSubmitBtn = false;

      if (e.image_url && e.image_url.indexOf(',') > -1) {
        e.image_url = e.image_url.split(',');
      } else {
        e.image_url = [e.image_url];
      }
      this.formData = e;

      this.formDesc = formDescDetail;
    },
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      queryCheckList(params).then(res => {
        // console.log(res);
        this.data = [];
        if (res.code === 0) {
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
          this.tableLoading = false;
        }
      });
    },
    async handleSubmit(fields) {
      let params = {
        ...fields
      };
      let result;
      result = await saveTankerCheck(params);

      this.disabledForm = false;
      if (result && result.code === 0) {
        this.$message.success(result.msg);
        this.handleCancel();
        return result;
      } else {
        this.$message.error(result.msg);
      }
    },
    handleCancel() {
      this.visible = false;
      this.formData = { check_time: moment().format('yyyy-MM-DD') };
      this.formDesc = {};
      this.tankerList = [];
      this.fileList = [];
      this.dialogImageUrl = false;
      this.$refs['form'].resetForm();
      this.handleQuery({
        current: this.pagination.current,
        pageSize: this.pagination.pageSize
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: this.pagination.pageSize
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };

      exportCheck(params)
        .then(res => {
          this.$ExportFile(res, this.title);
          // const { code, msg } = res;
          // if (code !== 0) {
          //   this.$message({
          //     message: msg || '导出失败',
          //     type: 'error'
          //   });
          // }
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    // 上传到服务器
    submitUpload() {
      if (this.fileList.length != 0) {
        const isLt100M = this.fileList.every(
          file => file.size / 1024 / 1024 < 10
        );
        if (!isLt100M) {
          this.$message.error('请检查，单张图片大小不能超过10MB!');
        } else {
          this.fileData = new FormData(); // new formData对象
          this.$refs.upload.submit(); // 提交调用uploadFile函数
          this.disabledForm = true;
          axios
            .post('/api/info/tanker_check_up/img_add', this.fileData)
            .then(response => {
              if (response.data.code === 0) {
                console.log(response.data);
                this.formData.only_id = response.data.data;
                const fd = this.formData;
                this.handleSubmit(fd);
              } else {
                this.disabledForm = false;
                this.$message({
                  message: response.data.msg,
                  type: 'error'
                });
              }
            });
        }
      } else {
        this.formData.only_id = '';
        this.disabledForm = true;
        this.handleSubmit(this.formData);
      }
    },
    //监控上传文件列表
    handleChange(file, fileList) {
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find(f => f.name === file.name);
      if (existFile) {
        this.$message.error('当前文件已经存在!');
        fileList.pop();
      }
      this.fileList = fileList;
    },
    //移除
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handleBeforeRemove(file) {
      return this.$confirm(`确定移除图片 ${file.name} ？`);
    },
    // 选取文件超过数量提示
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.fileLimit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
    },
    uploadFile(file) {
      this.fileData.append('image[]', file.file); // append增加数据
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles';

.form-wrap {
  margin: 0 auto;
  width: 480px;
}
</style>
<style lang="scss">
@import '@/styles';
.dialog {
  .el-dialog__header {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 0 0 32px;
    box-sizing: border-box;
    color: #fff;
    background-color: $--color-primary;
  }
  .el-dialog__title {
    font-size: 18px;
  }
  .el-dialog__title,
  .el-dialog__headerbtn,
  .el-dialog__close {
    color: #fff !important;
  }
}
</style>
