import { GetMachineList } from '@/api/gas_type_check';
import { getGasData } from '@/api/global';

export const columns = [
  {
    title: '序号',
    key: 'index',
    width: 64,
    align: 'center',
    fixed: true,
    scopedSlots: { customRender: 'index' }
  },
  {
    title: '加油站名称',
    key: 'site_name',
    fixed: true,
    minWidth: 150
  },
  {
    title: '加油机名称',
    key: 'tanker_name',
    minWidth: 80
  },
  {
    title: '生产厂家',
    key: 'manufacturer',
    minWidth: 150
  },
  {
    title: '加油机出厂编号',
    key: 'factory_num',
    // width: 140,
    minWidth: 100
  },
  {
    title: '出厂日期',
    key: 'start_date',
    width: 100
  },
  {
    title: '检查时间',
    key: 'check_time',
    width: 100
  },
  {
    title: '检查情况',
    key: 'situation',
    minWidth: 200
  },
  {
    title: '操作',
    key: 'actions',
    width: 120,
    extraType: 'button',
    scopedSlots: { customRender: 'actions' }
  }
];

export const formDesc = {
  site_id: {
    type: 'select',
    label: '加油站名称',
    layout: 24,
    rules: [{ required: true, message: '请选择加油站' }],
    prop: { text: 'name', value: 'id' },
    options: async () => {
      const result = await getGasData();
      if (result && result.code === 0) {
        return result.data;
      } else {
        return [];
      }
    },
    attrs: {
      filterable: true
    }
  },
  tanker_id: {
    type: 'select',
    label: '加油机名称',
    optionsLinkageFields: ['site_id'],
    layout: 12,
    rules: [{ required: true, message: '请输入加油机' }],
    prop: { text: 'name', value: 'id' },
    options: async data => {
      console.log('history', data);
      if (data.site_id) {
        const result = await GetMachineList({ site_id: data.site_id });
        if (result && result.code === 0) {
          return result.data;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    attrs: {
      filterable: true
    }
  },
  check_time: {
    type: 'date',
    label: '检查时间',
    layout: 12,
    rules: [{ required: true, message: '请输入检查时间' }],
    attrs: {
      'value-format': 'yyyy-MM-dd'
    }
  },
  situation: {
    type: 'textarea',
    label: '检查情况',
    rules: [{ required: true, message: '请输入检查情况' }],
    attrs: {
      autosize: { minRows: 4, maxRows: 6 }
    }
  },
  opinion: {
    type: 'textarea',
    label: '整改意见',
    rules: [{ required: true, message: '请输入整改意见' }],
    attrs: {
      autosize: { minRows: 4, maxRows: 6 }
    }
  },
  remarks: {
    type: 'textarea',
    label: '检查备注',
    attrs: {
      autosize: { minRows: 4, maxRows: 6 }
    }
  },
  only_id: {
    // type: 'textarea',
    label: '请上传图片'
  }
};

export const formDescDetail = {
  site_name: {
    type: 'text',
    label: '加油站名称：',
    layout: 24
  },
  tanker_name: {
    type: 'text',
    label: '加油机名称：',
    layout: 12
  },
  factory_num: {
    type: 'text',
    label: '出厂编号：',
    layout: 12
  },
  start_date: {
    type: 'text',
    label: '出厂日期：',
    layout: 12
  },
  check_time: {
    type: 'text',
    label: '检查时间：',
    layout: 12
  },
  situation: {
    type: 'text',
    label: '检查情况：'
  },
  opinion: {
    type: 'text',
    label: '整改意见：'
  },
  remarks: {
    type: 'text',
    label: '检查备注：'
  },
  image_url: {
    label: '图片：',
    type: 'image',
    title: '是否必填',
    style: {
      // 可以在此调整大小
      width: '150px',
      height: '150px'
    },
    attrs: {
      isShowPreview: true // 默认值开启图片预览功能，可以设置为false，关闭
    }
  }
};
